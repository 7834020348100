import './App.css';
import githubLogo from './images/github-mark-white.png'; 
import myPicture from './images/me.jpg'

function App() {
  return (
    <div className="background">
      <div className="home">

    
        <img
          className="main-image"
          alt="Me"
          src={myPicture}
        />

        <p className="main-description">Hello and welcome to my personal page!</p>
        <p className="main-description">Feel free to checkout any of my pages and contact me!</p>


        <div className='link-bubble'>
          {/* <img
            className="link-images"
            alt="About Us logo"
            src={etsyLogo}
          /> */}
          <a className="link-bubble-text" href="TODO">{"About me"}</a>
        </div>

        <div className='link-bubble'>
          <img
            className="link-images"
            alt="Github logo"
            src={githubLogo}
          />
          <a className="link-bubble-text" href="https://github.com/abelcollins">{"My Github"}</a>
        </div>

{/* 
        <div className='link-bubble'>
          <a className="link-bubble-text" href="TODO">{"Check Out My Etsy!"}</a>
          <img
            className="link-images"
            alt="Etsy logo"
            src={etsyLogo}
          />        
        </div>


        <div className='link-bubble'>
          <a className="link-bubble-text" href="TODO">{"Check Out My Instagram!"}</a>
          <img
            className="link-images"
            alt="Instagram logo"
            src={instagramLogo}
          />        
        </div>


        <div className='link-bubble'>
        <a className="link-bubble-text" href="TODO">{"Check Out My Facebook!"}</a>
          <img
            className="link-images"
            alt="Facebook logo"
            src={facebookLogo}
          />
        </div> */}


      </div>
    </div>
  );
}

export default App;